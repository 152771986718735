import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { UPDATE_COMMENT } from '../../constants/interactions';
import { COMMENT, REPLY } from '../../constants/form-types';
import CommentFormWrapper from '../../components/comment-form-wrapper';
import CommentForm from '../../components/comment-form';

export class CommentEditForm extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.comment._id !== nextProps.comment._id;
  }
  handleSubmit = ({ values }, fastForm) => {
    const { comment, updateCommentPromisified, interactionStarted } = this.props;
    interactionStarted(UPDATE_COMMENT);

    updateCommentPromisified({ ...comment, ...values }).catch(() => {
      fastForm.stopSubmit();
    });
  };

  render = () => {
    const { stopEditingComment, size, postType } = this.props;
    const { comment } = this.props;

    return (
      <CommentFormWrapper>
        <CommentForm
          formInitialValues={comment}
          onSubmit={this.handleSubmit}
          onCancel={() => stopEditingComment()}
          formName={`comment-edit-${comment._id}`}
          editedDate={comment.editedDate}
          size={size}
          postType={postType}
          type={comment.parentId ? REPLY : COMMENT}
        />
      </CommentFormWrapper>
    );
  };
}

CommentEditForm.propTypes = {
  comment: PropTypes.object.isRequired,
  stopEditingComment: PropTypes.func,
  updateCommentPromisified: PropTypes.func,
  size: PropTypes.string,
  interactionStarted: PropTypes.func,
  postType: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  updateCommentPromisified: actions.updateCommentPromisified,
  stopEditingComment: actions.stopEditingComment,
  interactionStarted: actions.interactionStarted,
});

// prettier-ignore
export default flowRight(
  connect(mapRuntimeToProps),
)(CommentEditForm);
