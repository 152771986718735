let isDepressed = false;

export function isAnyKeyDepressed() {
  return isDepressed;
}

function setDepressed() {
  isDepressed = true;
}

function setNotDepressed() {
  isDepressed = false;
}

export const listenToKeypress = () => {
  document.addEventListener('keydown', setDepressed);
  document.addEventListener('keyup', setNotDepressed);
};

export const dontListenToKeypress = () => {
  document.removeEventListener('keydown', setDepressed);
  document.removeEventListener('keyup', setNotDepressed);
};
