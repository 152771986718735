export const createInitialDraftJsContentWithText = text => ({
  blocks: [
    {
      key: 'foo',
      text,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
});
